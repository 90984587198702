export const IMAGES = [
    {
      id: 0,
      url:
        "assets/img/title.png"
    },
    {
      id: 1,
      url:
      "assets/img/homeback.jpg"
    },
  ]
export interface imageInferface{
    id:number;
    url:string;
}
  