import * as React from "react";
import {useState, useContext, useRef, useEffect} from "react";
import { Button, Row, Col, BackTop, Menu   } from 'antd';
import Loading from '../../component/Loading';
import './index.less';
const LastSales: React.FC = () => {
    const [isLoading, setLoading] = useState(true);
    useEffect(()=>{
        setTimeout(()=>{
            setLoading(true);
        },3000)
       
    },[])
    return (
        <>
            {
                isLoading?
                    <Loading title="coming soon"/>
                :
                <>
                </>
            }
            
        </>
        
    )
}

export default LastSales;